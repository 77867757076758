<template>
  <div class="yxb_operation_wrap">
    <div class="yxb_head">
      <div class="main head">
        <div class="head_title">
          <i class="iconfont el-icon-arrow-left" @click="goReturn()"></i>
          {{ objdata.title }}
        </div>
        <div class="head_zd">
          <el-button
            type="primary"
            @click="tozd()"
            v-if="isAnswer == 1 && myanswerlist.answer_again ==1"
            >重新作答</el-button
          >
        </div>
      </div>
    </div>
    <div class="yxb_content">
      <div class="main">
        <!-- 作业题干 -->
        <div class="yxb_trunk">
          <div class="trunk_head">
            <div class="trunk_title_left">
              <img src="./assets/images/uxb_icon04.png" alt="" />
              {{
                objdata.type == 1 ? "图文" : objdata.type == 2 ? "文件" : ""
              }}类型
            </div>
          </div>
          <pre class="trunk_const">
            {{ objdata.content }}
          </pre>
          <div class="trunk_img" v-if="objdataimageList.length > 0">
            <img
              v-for="(item, index) in objdataimageList"
              :key="index"
              :src="item"
              @click="imgClick(item)"
            />
          </div>
          <div class="trunk_audio" v-if="objdata.audio_path">
            <span>音频读题</span>
            <audio
              :src="objdata.audio_path"
              controls="controls"
              @play="audiobofang()"
            ></audio>
          </div>
          <div class="trunk_video" v-if="objdata.video_path">
            <video controls preload="auto" @play="videobofang()">
              <source :src="objdata.video_path" />
            </video>
          </div>
        </div>
        <!-- 思路点拨 -->
        <template v-if="thoughtlist.length > 0">
          <CourseAnalyze
            v-for="(item, index) in thoughtlist"
            :key="index + '1'"
            :lists="item"
            :type="1"
          ></CourseAnalyze>
        </template>
        <!-- 我的答案 -->
        <CourseMyAnswer
          v-if="AnswerStatus"
          @editMyAnswer="editMyAnswer"
          :myanswerlist="myanswerlist"
          :is_score="objdata.is_score"
        ></CourseMyAnswer>
        <!-- 题干解析 -->
        <template
          v-if="
            (answerlist.length > 0 && show_analysis == 0) ||
            (answerlist.length > 0 &&
              myanswerlist.add_time &&
              show_analysis == 1)
          "
        >
          <CourseAnalyze
            v-for="(item, index) in answerlist"
            :key="index"
            :lists="item"
            :type="2"
          ></CourseAnalyze>
        </template>
        <!--        <div v-if="mockflag" style="height: 350px"></div>-->
      </div>

      <div class="main">
        <!-- 模拟作答 -->
        <div class="main mock_answer" v-if="mockflag">
          <div class="mock_answer_title" @click="changeSheetCard">
            <img src="./assets/images/uxb_bg02.png" />
            <span>模拟作答</span>
            <i
              class="el-icon-arrow-down rightIcon"
              v-if="visibleMockAnswer"
            ></i>
            <i class="el-icon-arrow-up rightIcon" v-else></i>
          </div>
          <div class="sheet-content" v-show="visibleMockAnswer">
            <div class="scroll-bar">
              <div class="textareaBox" v-if="objdata.type == 1">
                <div class="textareaimg">
                  <textarea
                    rows="8"
                    placeholder="请输入内容"
                    v-model="desc"
                    @input="descInput"
                  ></textarea>
                  <!--                  <span class="word_count">{{ remnant }}/500</span>-->
                  <div class="imglist" ref="jo" v-if="VisibleUpload">
                    <el-upload
                      :data="upld"
                      action="/api/uploader/v1/upload_file"
                      list-type="picture-card"
                      :limit="9"
                      :headers="{
                        'Uxb-User-Agent': 'webVersion:v1.0.0',
                      }"
                      :file-list="fileList"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :on-success="sucesspic"
                      :on-exceed="handleExceed"
                      :before-upload="beforeUpload"
                      :on-error="handleError"
                      accept=" .jpg, .png"
                    >
                      <i class="el-icon-plus">
                        <span>{{ imgremnant }}/9</span>
                      </i>
                    </el-upload>
                  </div>
                  <img
                    class="imgup"
                    v-else
                    @click="clickUpload"
                    src="./assets/images/uxb_bg04.png"
                  />
                </div>
              </div>
              <div class="file_upload" v-else>
                <el-upload
                  ref="uploadFile"
                  class="upload"
                  action="/api/uploader/v1/upload_file"
                  :limit="1"
                  :headers="{
                    'Uxb-User-Agent': 'webVersion:v1.0.0',
                  }"
                  :file-list="backList"
                  :on-remove="handleRemove1"
                  :on-success="sucesspic1"
                  :on-exceed="handleExceed1"
                  :before-upload="beforeUpload1"
                  :on-error="handleError1"
                  :data="upld1"
                >
                  <div v-if="!visibleFile">
                    <div>
                      <div slot="tip" class="el-upload__tip">
                        <img src="./assets/images/uxb_icon06.png" />
                        请上传文档！
                      </div>
                      <el-button size="small" type="primary">选择</el-button>
                    </div>
                    <div slot="tip" class="el-upload__tip el-upload__ti">文件大小不超过10M！</div>
                  </div>
                </el-upload>
              </div>
              <input
                class="submit_answer"
                :class="{ bluecolor: visibleFile }"
                @click="onSubmit"
                type="button"
                value="提交"
              />
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible1">
        <img width="100%" :src="dialogImageUrl1" alt="" />
      </el-dialog>
      <div class="offcn_Uxbbg" v-if="IsReturn">
        <div class="offcn_Uxbbgtc">
          <div class="offcn_Uxbbgtcsuccess">
            <h6>确定退出答题吗？</h6>
            <p>退出后已作答的内容将不会保留</p>
            <div class="offcn_Uxbbgtcerrordiv">
              <span @click="goReturnclose(1)">取消</span>
              <span @click="goReturnclose(2)">确定</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  info,
  respondenceinfo,
  addrespondence,
  answerinfo,
  addworkanswer,
} from "./assets/api";
import CourseAnalyze from "./assets/components/course_analyze";
import CourseMyAnswer from "./assets/components/course_myanswer";
import { changeStr, pinjie, objKeySort } from "../../../assets/js/sign";
import Md5 from "md5";
export default {
  name: "course_operation",
  components: { CourseAnalyze, CourseMyAnswer },

  data() {
    return {
      AnswerStatus: false, //作答状态
      mockflag: false,
      visibleMockAnswer: true, //模拟作答展示
      textarea: "",
      dialogImageUrl: "",
      remnant: "0",
      imgremnant: "0",
      showUpload: false,
      fileList: [],
      backList: [],
      myanswerlist: {},
      visibleFile: false,
      assignment_id: "",
      course_id: "",
      lesson_id: "",
      dialogImageUrl1: "",
      dialogVisible1: false,
      objdata: {}, //整体数据
      thoughtlist: [],
      answerlist: [],
      objdataimageList: [],
      desc: "",
      upld: {
        siteid: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        bucket_type: 2,
        up_config: 1,
        uploadfiletype: 1,
        operation_mode: 1,
        stime: "",
        sign: "",
      },
      upld1: {
        siteid: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        bucket_type: 2,
        up_config: 1,
        uploadfiletype: 1,
        operation_mode: 1,
        stime: "",
        sign: "",
      },
      VisibleUpload: false,
      IsReturn: false,
      Istrue: false,
      document_name: "",
      document_path: "",
      document_type: 0,
      show_analysis: "",
      chang_img: [],
      chang_zip: [],
      newHomework: 2, //1为新作业，2为老作业
      answer_id: 0, //作答id
      cst_id: 0, //班级id
      id: 0, //作业壳子id
      isAnswer: 1, //是否查看作业报告 1是查看 2是作答
    };
  },
  beforeRouteEnter (to, from, next) {
    console.log(from)
			if(from.path=='/newlearn'){
        sessionStorage.setItem('activeMenuPath',7)
        next()
      }else{
        next()
      }
		},
  mounted() {
    console.log(this.$router)
    if (
      this.$route.query.assignment_id != undefined &&
      this.$route.query.assignment_id != ""
    ) {
      this.assignment_id = this.$route.query.assignment_id;
    }
    if (
      this.$route.query.course_id != undefined &&
      this.$route.query.course_id != ""
    ) {
      this.course_id = this.$route.query.course_id;
    }
    if (
      this.$route.query.lesson_id != undefined &&
      this.$route.query.lesson_id != ""
    ) {
      this.lesson_id = this.$route.query.lesson_id;
    }
    if (
      this.$route.query.newHomework != undefined &&
      this.$route.query.newHomework != ""
    ) {
      this.newHomework = this.$route.query.newHomework;
    }
    if (
      this.$route.query.answer_id != undefined &&
      this.$route.query.answer_id != ""
    ) {
      this.answer_id = this.$route.query.answer_id;
    }
    if (
      this.$route.query.answer_id != undefined &&
      this.$route.query.answer_id != ""
    ) {
      this.answer_id = this.$route.query.answer_id;
    }
    if (
      this.$route.query.cst_id != undefined &&
      this.$route.query.cst_id != ""
    ) {
      this.cst_id = this.$route.query.cst_id;
    }
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    if (
      this.$route.query.isAnswer != undefined &&
      this.$route.query.isAnswer != ""
    ) {
      this.isAnswer = this.$route.query.isAnswer;
    }
    this.info();
  //1 调取开始作答报错接口   2不调取
    if (this.newHomework == 1) {
      this.answerinfo();
    } else if (this.newHomework == 2){
      this.mockflag = true;
          this.AnswerStatus = false;
    }
      else {
      this.respondenceinfo();
    }
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.goBack, false);
    // }
  },
  watch: {
    "$route.params": function () {
      location.reload();
    },
  },
  methods: {
    // goBack() {
    //     this.IsReturn = true;
    //     this.Istrue = true;
    // },
    //重新作答
    tozd() {
      this.$router.push({
        path: "/homework",
        query: {
          assignment_id: this.$route.query.assignment_id,
          id: this.$route.query.id,
          course_id: this.$route.query.course_id,
          cst_id: this.$route.query.cst_id,
          answer_id: this.$route.query.answer_id,
          newHomework: 2,
          isAnswer: 2
        },
      });
    },
    //新作业
    answerinfo() {
      let params = {
        course_assignment_id: this.id,
        course_id: this.course_id,
        cst_id:  this.$route.query.cst_id,
        answer_id: this.answer_id,
      };
      answerinfo(params).then((res) => {
        if (res.code == 0) {

            if (res.data.type == 1) {
            this.desc = res.data.content;
            this.fileList = res.data.answer_img;
            if (this.fileList.length > 0) {
              this.VisibleUpload = true;
            }
          } else {
            let backList = [];
            if (res.data.document_path.length > 0) {
              res.data.document_path.map((item) => {
                let obj = {
                  name: "",
                  url: ""
                };
                obj.name = res.data.document_name;
                obj.url = item.path;
                backList.push(obj);
              });
            }
            this.backList = backList;
          }
          if (this.isAnswer == 1) {
            this.visibleFile = false;
            this.mockflag = false;
            this.AnswerStatus = true;
            this.myanswerlist = res.data;
          } else {
            this.visibleFile = true;
            this.mockflag = true;
            this.AnswerStatus = false;
          }


        } else if (res.message == "开始作答") {
          this.mockflag = true;
          this.AnswerStatus = false;
        }
      });
    },
    goReturn() {
      if(this.isAnswer == 1){
        this.$router.go(-1);
      }else {
        if (JSON.stringify(this.myanswerlist) != "{}") {
          if (this.myanswerlist.comments.length > 0) {
            this.$router.go(-1);
          } else {
            this.IsReturn = true;
          }
        } else {
          this.IsReturn = true;
        }
      }
    },
    goReturnclose(data) {
      this.IsReturn = false;
      if (data == 2) {
        this.$router.go(-1);
      } else if (data == 1) {
        // this.$router.go(-2);
        this.IsReturn = false;
      }
    },
    audiobofang() {
      var audios = document.getElementsByTagName("audio");
      var videos = document.getElementsByTagName("video");
      [].forEach.call(videos, function (i) {
        i.pause();
      });
      function pauseAll() {
        var self = this;
        [].forEach.call(audios, function (i) {
          i !== self && i.pause();
        });
      }
      // 给play事件绑定暂停函数
      [].forEach.call(audios, function (i) {
        i.addEventListener("play", pauseAll.bind(i));
      });
    },
    videobofang() {
      var audios = document.getElementsByTagName("audio");
      var videos = document.getElementsByTagName("video");
      [].forEach.call(audios, function (i) {
        i.pause();
      });
      function pauseAll() {
        var self = this;
        [].forEach.call(videos, function (i) {
          i !== self && i.pause();
        });
      }
      // 给play事件绑定暂停函数
      [].forEach.call(videos, function (i) {
        i.addEventListener("play", pauseAll.bind(i));
      });
    },
    imgClick(file) {
      this.dialogImageUrl1 = file;
      this.dialogVisible1 = true;
    },
    info() {
      let params = {
        assignment_id: this.assignment_id,
        course_id: this.course_id,
        lesson_id: this.lesson_id,
      };
      info(params).then((res) => {
        if (res.code == 0) {
          this.objdata = res.data;
          this.objdataimageList = res.data.image;
          if (this.objdata.thought.length > 0) {
            this.objdata.thought.map((item) => {
              this.$set(item, "openFlag", false);
            });
            this.thoughtlist = this.objdata.thought;
          }
          if (this.objdata.answer.length > 0) {
            this.objdata.answer.map((item) => {
              this.$set(item, "openFlag", false);
            });
            this.answerlist = this.objdata.answer;
            this.show_analysis = res.data.show_analysis;
          }
        }
      });
    },
    respondenceinfo() {
      let params = {
        assignment_id: this.assignment_id,
        course_id: this.course_id,
      };
      respondenceinfo(params).then((res) => {
        if (res.code == 0) {
          this.AnswerStatus = true;
          this.mockflag = false;
          this.myanswerlist = res.data;
        } else {
          this.AnswerStatus = false;
          this.mockflag = true;
        }
      });
    },
    // 点击模拟作答
    changeSheetCard() {
      this.visibleMockAnswer = !this.visibleMockAnswer;
    },
    //上传图片
    sucesspic(response) {
      if (response.code == 0) {
        this.fileList.push({
          url: response.data.inline_url,
          path: response.data.uuid,
        });
        this.imgremnant = this.fileList.length;
        if (this.fileList.length > 0) {
          this.visibleFile = true;
        } else {
          this.visibleFile = false;
        }
      }
    },
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (file.url == item.url) {
          this.chang_img.push(file.path);
          this.fileList.splice(index, 1);
        }
      });
      this.imgremnant = this.fileList.length;
      if (this.fileList.length > 0) {
        this.visibleFile = true;
      } else {
        this.visibleFile = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    // 文件上传的状态
    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var reg = new RegExp('[\\\\/:*?"<>|]');
      let errors = reg.test(file.name);
      if (errors) {
        this.$message.warning(
          "文件名不能包含“?”、“、”、“╲”、“/”、“*”、““”、“”“、“”、“|”等特殊字符"
        );
        return false;
      }
      var leng = file.name.substr(0, file.name.lastIndexOf(".")).length;
      if (leng > 255) {
        this.$message.warning("文件名太长");
        return false;
      }
      if (leng == 0) {
        this.$message.warning("文件名不能为空");
        return false;
      }
      const extension1 = testmsg === "jpg";
      const extension2 = testmsg === "png";
      const isLimit = file.size / 1024 / 1024;
      if (!extension1 && !extension2) {
        this.$message.warning("您只能上传以下类型文件：.jpg, .png!");
      }
      if (extension1 || extension2) {
        if (isLimit > 10) {
          this.$message.warning("文件大小不得超过10M");
          return false;
        } else {
          this.upld.sign = "";
          this.upld.stime = "";
          this.upld.stime = parseInt(new Date().getTime() / 1000);
          this.upld.sign = Md5(changeStr(pinjie(objKeySort(this.upld))));
        }
      }
      return extension1 || extension2;
    },
    // 文件上传限制
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传 ${fileList.length} 个文件`);
    },
    // 文件上传失败时的钩子
    handleError() {
      this.$message.error("文件上传失败");
    },
    clickUpload() {
      this.VisibleUpload = !this.VisibleUpload;
    },
    // 文本框输入字数统计
    descInput() {
      if (this.desc.length > 0) {
        this.visibleFile = true;
      } else {
        this.visibleFile = false;
      }
    },
    // 提交
    onSubmit() {
      if (this.visibleFile) {
        var params = {};
        if (this.newHomework == 1||this.newHomework == 2) {
          if (this.objdata.type == 1) {
            params = {
              course_assignment_id: this.id,
              course_id: this.course_id,
              cst_id: this.cst_id,
              document_type: 0,
              content: this.desc,
              answer_img: "",
              chang_img: "[" + this.chang_img.toString() + "]",
            };
            if (this.fileList.length > 0) {
              let arr = [];
              this.fileList.map((item) => {
                arr.push(item.path);
              });
              params.answer_img = arr.toString();
            }
          } else {
            params = {
              course_assignment_id: this.id,
              course_id: this.course_id,
              cst_id: this.cst_id,
              document_type: this.document_type,
              document_name: this.document_name,
              document_path: this.document_path,
              answer_img: "",
              content: "",
              chang_img: "[" + this.chang_img.toString() + "]",
            };
          }
          addworkanswer(params).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              // this.info();
              // this.answerinfo();
              this.$nextTick(() => {
                this.$router.push({
                  path: "/newlearn",
                  query: { cst_id: this.cst_id, course_id: this.course_id },
                });
              });
              window.scrollTo(0, 0);
            } else {
              this.$message.error("提交失败");
            }
          });
        } else {
          if (this.objdata.type == 1) {
            params = {
              assignment_id: this.assignment_id,
              course_id: this.course_id,
              lesson_id: this.lesson_id,
              document_type: 0,
              content: this.desc,
              answer_img: "",
              chang_img: "[" + this.chang_img.toString() + "]",
            };
            if (this.fileList.length > 0) {
              let arr = [];
              this.fileList.map((item) => {
                arr.push(item.path);
              });
              params.answer_img = arr.toString();
            }
          } else {
            params = {
              assignment_id: this.assignment_id,
              course_id: this.course_id,
              lesson_id: this.lesson_id,
              document_type: this.document_type,
              document_name: this.document_name,
              document_path: this.document_path,
              chang_img: "[" + this.chang_img.toString() + "]",
            };
          }
          addrespondence(params).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.info();
              this.respondenceinfo();
              let ids = this.$route.query.course_id;
              this.$nextTick(() => {
                this.$router.push({
                  path: "/learn",
                  query: { course_id: ids },
                });
              });
              window.scrollTo(0, 0);
            } else {
              this.$message.error("提交失败");
            }
          });
        }
      }
    },
    editMyAnswer() {
      if (this.objdata.type == 1) {
        this.mockflag = true;
        this.desc = this.myanswerlist.content;
        this.fileList = this.myanswerlist.answer_img;
        this.VisibleUpload = true;
      } else if (this.objdata.type == 2) {
        this.mockflag = true;
        this.backList = this.myanswerlist.document_path;
        this.visibleFile = true;
      }
    },
    //上传图片
    sucesspic1(response, files) {
      if (response.code == 0) {
        this.backList.push({
          url: response.data.inline_url,
          path: response.data.uuid,
          name: files.name,
        });
        if (this.backList.length > 0) {
          this.visibleFile = true;
        } else {
          this.visibleFile = false;
        }
        let typearr = files.name.split(".");
        var type = typearr[typearr.length - 1];
        if (type == "zip") {
          this.document_type = 1;
        } else if (type == "rar") {
          this.document_type = 2;
        } else if (type == "7z") {
          this.document_type = 3;
        } else if (type == "pdf") {
          this.document_type = 4;
        } else if (type == "txt") {
          this.document_type = 5;
        } else if (type == "doc") {
          this.document_type = 6;
        } else if (type == "xls") {
          this.document_type = 7;
        } else if (type == "xlsx") {
          this.document_type = 8;
        } else if (type == "pdf") {
          this.document_type = 9;
        } else if (type == "docx") {
          this.document_type = 10;
        } else if (type == "jpg") {
          this.document_type = 11;
        } else if (type == "jpeg") {
          this.document_type = 12;
        } else if (type == "png") {
          this.document_type = 13;
        } else if (type == "pptx") {
          this.document_type = 14;
        } else {
          this.document_type = 0;
        }
        this.document_name = files.name;
        this.document_path = response.data.uuid;
      } else {
        this.$message.error(response.message);
        let uid = files.uid; // 关键作用代码，去除文件列表失败文件
        let idx = this.$refs.uploadFile.uploadFiles.findIndex(
          (item) => item.uid === uid
        ); // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
        this.$refs.uploadFile.uploadFiles.splice(idx, 1); // 关键作用代码，去除文件列表失败文件
      }
    },
    handleRemove1(file) {
      this.backList.forEach((item, index) => {
        if (file.url == item.url) {
          this.chang_img.push(file.path);
          this.backList.splice(index, 1);
        }
      });
      this.imgremnant = this.backList.length;
      if (this.backList.length > 0) {
        this.visibleFile = true;
      } else {
        this.visibleFile = false;
      }
    },
    // 文件上传的状态
    beforeUpload1(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var reg = new RegExp('[\\\\/:*?"<>|]');
      let errors = reg.test(file.name);
      if (errors) {
        this.$message.warning(
          "文件名不能包含“?”、“、”、“╲”、“/”、“*”、““”、“”“、“”、“|”等特殊字符"
        );
        return false;
      }
      var leng = file.name.substr(0, file.name.lastIndexOf(".")).length;
      if (leng > 255) {
        this.$message.warning("文件名太长");
        return false;
      }
      if (leng == 0) {
        this.$message.warning("文件名不能为空");
        return false;
      }
      const extension1 = testmsg === "zip";
      const extension2 = testmsg === "rar";
      const extension3 = testmsg === "7z";
      const extension4 = testmsg === "txt";
      const extension5 = testmsg === "pdf";
      const extension6 = testmsg === "doc";
      const extension7 = testmsg === "xls";
      const extension8 = testmsg === "xlsx";
      const extension9 = testmsg === "ppt";
      const extension10 = testmsg === "docx";
      const extension11 = testmsg === "jpg";
      const extension12 = testmsg === "jpeg";
      const extension13 = testmsg === "png";
      const extension14 = testmsg === "pptx";
      const isLimit = file.size / 1024 / 1024;
      if (
        !extension1 &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5 &&
        !extension6 &&
        !extension7 &&
        !extension8 &&
        !extension9 &&
        !extension10 &&
        !extension11 &&
        !extension12 &&
        !extension13 &&
        extension14
      ) {
        this.$message.warning(
          "您只能上传以下类型文件：.zip, .rar, .7z, .pdf, .txt, .doc, .xls, .xlsx, .ppt, .docx, .jpg, .jpeg, .png!"
        );
      }
      if (
        extension1 ||
        extension2 ||
        extension3 ||
        extension4 ||
        extension5 ||
        extension6 ||
        extension7 ||
        extension8 ||
        extension9 ||
        extension10 ||
        extension11 ||
        extension12 ||
        extension13 ||
        extension14
      ) {
        if (isLimit > 10) {
          this.$message.warning("文件大小不得超过10M");
          return false;
        } else {
          this.upld1.sign = "";
          this.upld1.stime = "";
          this.upld1.stime = parseInt(new Date().getTime() / 1000);
          this.upld1.sign = Md5(changeStr(pinjie(objKeySort(this.upld1))));
        }
      }
      return (
        extension1 ||
        extension2 ||
        extension3 ||
        extension4 ||
        extension5 ||
        extension6 ||
        extension7 ||
        extension8 ||
        extension9 ||
        extension10 ||
        extension11 ||
        extension12 ||
        extension13 ||
        extension14
      );
    },
    // 文件上传限制
    handleExceed1(files, fileList) {
      this.$message.warning(`最多上传 ${fileList.length} 个文件`);
    },
    // 文件上传失败时的钩子
    handleError1() {
      this.$message({
        showClose: true,
        message: "上传失败",
        type: "error",
        duration: 0,
      });
    },
  },
  destroyed() {
    // window.removeEventListener("popstate", this.goBack, false);
  },
};
</script>

<style lang="scss" scoped>
.el-upload__ti{
  font-size: 12px!important;
  color: #606266!important;
  margin-top: 7px!important;
}
.offcn_Uxbbg {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  .offcn_Uxbbgtc {
    background: #fff;
    width: 400px;
    border-radius: 20px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .offcn_Uxbbgtcsuccess {
    padding: 50px 0 0 0;
    text-align: center;
    h6 {
      font: bold 20px/24px "Microsoft YaHei";
      color: #333333;
    }
    p {
      font: 16px/20px "Microsoft YaHei";
      color: #666666;
      margin-top: 10px;
    }
    .offcn_Uxbbgtcerrordiv {
      margin-top: 50px;
      display: flex;
      border-top: 1px solid #eeeeee;
      span {
        flex: 1;
        font: 16px/58px "Microsoft YaHei";
        color: #666666;
        cursor: pointer;
      }
      span:last-child {
        color: var(--change-color) ;
        border-left: 1px solid #eeeeee;
      }
    }
  }
}
.head_title {
  display: flex;
  align-items: center;
}
.yxb_operation_wrap {
  width: 100%;
  height: 100%;
  .yxb_head {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    color: #333;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .main {
      .head_title {
        i {
          margin-right: 16px;
          vertical-align: top;
          font-size: 20px;
        }
        .head_right {
          float: right;
          font-size: 16px;
          color: #356bfc;
        }
      }
    }
  }
  .yxb_content {
    .main {
      .yxb_trunk {
        padding: 23px 25px 30px;
        margin-bottom: 20px;
        border-radius: 8px;
        background: #fff;
        .trunk_head {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          color: #999;
          margin-bottom: 10px;
          .trunk_title_right {
            a {
              color: #507fff;
              cursor: pointer;
              &:hover {
                color: #507fff;
              }
            }
          }
        }
        .trunk_const { font-family: "Microsoft Himalaya";
          font-size: 16px;
          color: #333;
          line-height: 32px;
          margin-bottom: 12px; white-space: break-spaces;
        }
        .trunk_img {
          margin-bottom: 10px;
          > img {
            width: 130px;
            height: 130px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .trunk_audio {
          width: 480px;
          height: 70px;
          padding-top: 20px;
          background: #f1f3f4;
          border-radius: 8px;
          position: relative;
          margin-bottom: 20px;
          span {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            padding: 0 7px;
            height: 20px;
            font-size: 12px;
            color: #fff;
            line-height: 20px;
            text-align: center;
            background: #afbcc9;
            border-radius: 8px 0 8px 0;
          }
          audio {
            width: 100%;
          }
        }
        .trunk_video {
          width: 480px;
          height: 270px;
          background: #4b4c4e;
          border-radius: 8px;
          overflow: hidden;
          video {
            width: 100%;
            height: 100%;
          }
        }
      }
      .mock_answer {
        position: fixed;
        bottom: 0;
        z-index: 999;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        .mock_answer_title {
          width: 140px;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
          background-color: #9fa7ba;
          border-radius: 8px 8px 0px 0px;
          color: #fff;
          display: flex;
          flex-shrink: 0;
          cursor: pointer;
          position: relative;
          img {
            width: 20px;
            height: 20px;
            margin-left: 16px;
            margin-right: 2px;
            margin-top: 12px;
          }
          span {
            font-size: 16px;
            margin: 0 3px;
          }
          i {
            font-size: 11px;
            margin-top: 2px;
          }
          .rightIcon {
            margin-top: 14px;
            font-size: 14px;
            margin-left: 6px;
          }
        }
        .sheet-content {
          width: 100%;
          border-top: 6px solid#9fa7ba;
          border-radius: 0px 8px 0px 0px;
          height: auto;
          background-color: #ffffff;
          box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.08);
          overflow: hidden;
          padding-bottom: 30px;
          box-sizing: border-box;
          .scroll-bar {
            width: 100%;
            height: auto;
            margin-top: 30px;
            overflow: auto;
            .textareaBox {
              height: auto;
              padding: 0 25px;
              .textareaimg {
                position: relative;
                background: #f5f7fa;
                padding-bottom: 30px;
                width: 100%;
                textarea {
                  display: block;
                  resize: none;
                  width: 100%;
                  min-height: 136px;
                  max-height: 400px;
                  padding: 10px 15px 13px;
                  background: #f5f7fa;
                  border: 0;
                  box-sizing: border-box;
                  outline: none;
                }
                .word_count {
                  position: absolute;
                  right: 15px;
                  bottom: 10px;
                  color: #999;
                  font-size: 14px;
                }
                .imgup {
                  position: absolute;
                  left: 13px;
                  bottom: 13px;
                }
                .imglist {
                  padding: 0 11px;
                }
                ::v-deep .el-upload--picture-card {
                  width: 129px;
                  height: 129px;
                  line-height: 129px;
                  position: relative;
                  span {
                    display: block;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 30px;
                    font-size: 14px;
                    color: #aaa;
                  }
                }
                ::v-deep .hide .el-upload--picture-card {
                  display: none;
                }
                ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                  width: 129px;
                  height: 129px;
                  margin: 0 10px 10px 0;
                }
              }
            }
            .file_upload {
              padding: 0 25px;
              .el-upload__tip {
                float: left;
                margin-right: 55px;
              }
              /deep/.el-upload__tip {
                font-size: 14px;
              }
              .block {
                ::v-deep .el-upload-list {
                  display: block;
                  padding: 25px 25px;
                  background: #f5f7fa;
                  li {
                    margin: 0;
                    font-size: 16px;
                  }
                }
              }
              /deep/.el-upload-list__item:first-child {
                margin-top: 0;
              }
              /deep/.el-upload-list__item {
                padding: 15px 26px;
                background: #ebebeb;
                border-radius: 8px;
              }
              /deep/.el-upload-list__item-name {
                font: 16px/32px "Microsoft YaHei";
                color: #333;
                display: flex;
                align-items: center;
                /deep/.el-icon-document {
                  font: 22px/32px "Microsoft YaHei";
                }
              }
              /deep/.el-icon-circle-check,
              /deep/.el-icon-close {
                font: 22px/32px "Microsoft YaHei";
                top: 15px;
                right: 26px;
              }
              /deep/.el-upload-list__item-status-label {
                top: 15px;
                right: 26px;
              }
              /deep/.el-upload-list__item:hover {
                background: #ebebeb;
              }
              /deep/.el-upload-list__item .el-progress {
                top: auto;
                bottom: -2px;
                left: -5px;
              }
            }
            .submit_answer {
              display: block;
              width: 80px;
              height: 36px;
              background: #a8a8a8;
              font-size: 16px;
              color: #fff;
              line-height: 36px;
              margin: 20px auto 30px;
              border-radius: 4px;
            }
            .bluecolor {
              background:  var(--change-color) ;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
