<template>
  <div>
    <div class="yxb_my_answer">
      <div class="my_answer_head">
        <div class="my_answer_title_left">
          <em></em>
          <div>
            <img src="../images/uxb_bg08.png" alt="" />
            我的答案
          </div>
        </div>
        <span class="my_time">作答时间： {{ myanswerlist.add_time }}</span>
      </div>
      <div class="my_answer">
        <template v-if="myanswerlist.type == 1">
          <div class="my_answer_const">
            {{ myanswerlist.content }}
          </div>
          <div class="my_answer_img">
            <img
              v-for="(src, index) in myanswerlist.answer_img"
              :src="src.url"
              :key="index"
              @click="imganClick(src.url)"
            />
          </div>
        </template>
        <template v-if="myanswerlist.type == 2">
          <ul class="offcn_wj">
            <li
              v-for="(item, index) in myanswerlist.document_path"
              :key="index"
              class="clearfix"
              @click="gohref(item)"
            >
              <img src="../images/uxb_icon06.png" />
              <p>{{ myanswerlist.document_name }}</p>
            </li>
          </ul>
        </template>
        <div class="teacher_comments" v-if="myanswerlist.comments.length > 0">
          <div v-for="(item, index) in myanswerlist.comments" :key="index">
            <div class="my_answer_head">
              <div class="my_answer_title_left">
                <em class="tcem"></em>
                <div>
                  <img src="../images/uxb_bg09.png" alt="" />
                  老师点评
                </div>
              </div>
              <span class="my_time">点评时间： {{ item.comments_time }}</span>
              <div class="my_info">
                <p class="score" v-if="is_score == 1">
                  <span>评分</span>
                  <b :class="item.score < 60 ? 'red' : ''">{{ item.score }}</b>
                </p>
                <p class="score" v-else>
                  <span>{{
                    item.type == 1
                      ? "急需改进"
                      : item.type == 2
                      ? "优秀"
                      : item.type == 3
                      ? "良好"
                      : item.type == 4
                      ? "尚可"
                      : ""
                  }}</span>
                  <img
                    :src="
                      item.type == 1
                        ? require('../images/uxb_img05.png')
                        : item.type == 2
                        ? require('../images/uxb_img02.png')
                        : item.type == 3
                        ? require('../images/uxb_img03.png')
                        : item.type == 4
                        ? require('../images/uxb_img04.png')
                        : ''
                    "
                  />
                </p>
              </div>
            </div>
            <div class="my_answer">
              <div class="my_answer_const">
                {{ item.comments_record.content }}
              </div>
              <div
                class="my_answer_img"
                v-for="(item1, index1) in item.comments_record.image"
                :key="index1"
              >
                <img :src="item1" @click="imganClick(item1)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "course_my_answer",
  props: {
    myanswerlist: {
      type: Object,
    },
    is_score: Number,
  },
  data() {
    return {
      openFlag: true,
      teacher_list: [],
    };
  },
  methods: {
    //展开收起
    isOpen() {
      this.openFlag = !this.openFlag;
    },
    imganClick(data) {
      this.$parent.dialogImageUrl1 = data;
      this.$parent.dialogVisible1 = true;
    },
    gohref(data) {
      window.location.href = data.url;
      // if(this.$route.query.newHomework==1){
      //   window.location.href = data.path;
      // }else {
      //   window.location.href = data.url;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.yxb_my_answer {
  padding: 23px 25px 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #fff;
  .my_answer_head {
    display: flex;
    /*justify-content: space-between;*/
    font-size: 16px;
    color: #999;
    margin-bottom: 20px;
    .my_time {
      margin-left: 15px;
      font-size: 14px;
      line-height: 19px;
      color: #999;
      margin-top: 6px;
    }
    .my_answer_title_left {
      position: relative;
      color: #333;
      font-weight: bold;
      line-height: 28px;
      font-size: 20px;
      em {
        width: 70%;
        height: 10px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: #c7e2ff;
      }
      .tcem {
        background: #ffe2d8;
      }
      div {
        position: relative;
        z-index: 2;
      }
    }
    .my_answer_title_right {
      width: 58px;
      height: 26px;
      border: 1px solid #dbdbdb;
      border-radius: 14px;
      line-height: 26px;
      color: #507fff;
      text-align: center;
      cursor: pointer;
    }
  }
  .my_answer_const {
    font-size: 16px;
    color: #333;
    line-height: 32px;
    margin-bottom: 12px;
  }
  .my_answer_img {
    margin-bottom: 10px;
    img {
      width: 130px;
      height: 130px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .my_answer_audio {
    width: 480px;
    height: 70px;
    padding-top: 20px;
    background: #f1f3f4;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
    span {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      padding: 0 7px;
      height: 20px;
      font-size: 12px;
      color: #fff;
      line-height: 20px;
      text-align: center;
      background: #afbcc9;
      border-radius: 8px 0 8px 0;
    }
    audio {
      width: 100%;
    }
  }
  .my_answer_video {
    width: 480px;
    height: 270px;
    background: #4b4c4e;
    border-radius: 8px;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .my_answer {
    position: relative;
    .my_info {
      position: relative;
      .head_portrait {
        display: block;
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 0;
      }
      div {
        padding-top: 5px;
        float: left;
        line-height: normal;
        span {
          display: block;
          &.my_name {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 8px;
            color: #ff950a;
          }
        }
      }
      p {
        position: absolute;
        width: 200px;
        right: -978px;
        text-align: right;
        font-size: 13px;
        color: #333;
        i {
          font-size: 20px;
          font-weight: normal;
          margin-left: 15px;
        }
      }
    }
  }
  .teacher_comments {
    padding: 15px 20px 20px;
    background: #f8f8f8;
    border-radius: 8px;
    position: relative;
    .score {
      i {
        margin-left: 0;
      }
      b {
        color: #168bff;
        font-size: 23px;
        line-height: 30px;
        &.red {
          color: #ff4e4e;
        }
      }
      span {
        height: 29px;
        line-height: 29px;
        color: #999;
        font-size: 13px;
        margin-right: 10px;
      }
      img {
        vertical-align: bottom;
      }
    }
  }
}
.unfold {
  .my_answer_const {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .my_answer_img {
    display: none;
  }
  .my_answer_audio {
    display: none;
  }
  .my_answer_video {
    display: none;
  }
}
.offcn_wj {
  border-radius: 8px;
  padding: 10px 0;
  margin-top: 10px;
  li {
    img {
      width: 22px;
      height: 22px;
      float: left;
      margin-top: 5px;
    }
    p {
      font: 16px/32px "Microsoft YaHei";
      color: #333;
      float: left;
      margin-left: 10px;
      width: 95%;
    }
  }
}
</style>
