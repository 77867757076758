var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"yxb_my_answer"},[_c('div',{staticClass:"my_answer_head"},[_vm._m(0),_c('span',{staticClass:"my_time"},[_vm._v("作答时间： "+_vm._s(_vm.myanswerlist.add_time))])]),_c('div',{staticClass:"my_answer"},[(_vm.myanswerlist.type == 1)?[_c('div',{staticClass:"my_answer_const"},[_vm._v(" "+_vm._s(_vm.myanswerlist.content)+" ")]),_c('div',{staticClass:"my_answer_img"},_vm._l((_vm.myanswerlist.answer_img),function(src,index){return _c('img',{key:index,attrs:{"src":src.url},on:{"click":function($event){return _vm.imganClick(src.url)}}})}),0)]:_vm._e(),(_vm.myanswerlist.type == 2)?[_c('ul',{staticClass:"offcn_wj"},_vm._l((_vm.myanswerlist.document_path),function(item,index){return _c('li',{key:index,staticClass:"clearfix",on:{"click":function($event){return _vm.gohref(item)}}},[_c('img',{attrs:{"src":require("../images/uxb_icon06.png")}}),_c('p',[_vm._v(_vm._s(_vm.myanswerlist.document_name))])])}),0)]:_vm._e(),(_vm.myanswerlist.comments.length > 0)?_c('div',{staticClass:"teacher_comments"},_vm._l((_vm.myanswerlist.comments),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"my_answer_head"},[_vm._m(1,true),_c('span',{staticClass:"my_time"},[_vm._v("点评时间： "+_vm._s(item.comments_time))]),_c('div',{staticClass:"my_info"},[(_vm.is_score == 1)?_c('p',{staticClass:"score"},[_c('span',[_vm._v("评分")]),_c('b',{class:item.score < 60 ? 'red' : ''},[_vm._v(_vm._s(item.score))])]):_c('p',{staticClass:"score"},[_c('span',[_vm._v(_vm._s(item.type == 1 ? "急需改进" : item.type == 2 ? "优秀" : item.type == 3 ? "良好" : item.type == 4 ? "尚可" : ""))]),_c('img',{attrs:{"src":item.type == 1
                      ? require('../images/uxb_img05.png')
                      : item.type == 2
                      ? require('../images/uxb_img02.png')
                      : item.type == 3
                      ? require('../images/uxb_img03.png')
                      : item.type == 4
                      ? require('../images/uxb_img04.png')
                      : ''}})])])]),_c('div',{staticClass:"my_answer"},[_c('div',{staticClass:"my_answer_const"},[_vm._v(" "+_vm._s(item.comments_record.content)+" ")]),_vm._l((item.comments_record.image),function(item1,index1){return _c('div',{key:index1,staticClass:"my_answer_img"},[_c('img',{attrs:{"src":item1},on:{"click":function($event){return _vm.imganClick(item1)}}})])})],2)])}),0):_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my_answer_title_left"},[_c('em'),_c('div',[_c('img',{attrs:{"src":require("../images/uxb_bg08.png"),"alt":""}}),_vm._v(" 我的答案 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my_answer_title_left"},[_c('em',{staticClass:"tcem"}),_c('div',[_c('img',{attrs:{"src":require("../images/uxb_bg09.png"),"alt":""}}),_vm._v(" 老师点评 ")])])}]

export { render, staticRenderFns }