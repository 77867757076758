<template>
  <div>
    <div class="yxb_analyze" :class="lists.openFlag ? '' : 'unfold'">
      <div class="analyze_head">
        <div class="analyze_title_left">
          <em></em>
          <div>
            <img src="../images/uxb_bg05.png" alt="" />
            {{ lists.title }}
          </div>
        </div>
        <div class="analyze_title_right" @click="isOpen()">
          {{ lists.openFlag ? "收起" : "展开" }}
        </div>
      </div>
      <div class="analyze_const" v-if="lists.content">
        {{ lists.content }}
      </div>
      <template v-if="type==1">
        <div class="analyze_img" v-if="lists.thought_img&&lists.thought_img.length > 0">
            <img v-for="(src,index) in lists.thought_img" :src="src" :key="index+'1'" @click="imganClick(src)"/>
        </div>
      </template>
      <template v-if="type==2">
        <div class="analyze_img" v-if="lists.answer_img&&lists.answer_img.length > 0">
          <img v-for="(src,index) in lists.answer_img" :src="src" :key="index" @click="imganClick(src)"/>
        </div>
      </template>
      <template v-if="type==1">
        <div class="analyze_audio" v-if="lists.thought_audio_path">
          <span>音频读题</span>
          <audio :src="lists.thought_audio_path" controls="controls" @play="audiobofang1()"></audio>
        </div>
      </template>
      <template v-if="type==2">
        <div class="analyze_audio" v-if="lists.answer_audio_path">
          <span>音频读题</span>
          <audio :src="lists.answer_audio_path" controls="controls" @play="audiobofang1()"></audio>
        </div>
      </template>
      <template v-if="type==2">
        <div class="analyze_video" v-if="lists.answer_video_path">
          <video controls preload="auto" @play="videobofang1()">
            <source :src="lists.answer_video_path" />
          </video>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "course_analyze",
  props: {
    lists: {
      type: Object
    },
    type:Number
  },
  data() {
    return {

    };
  },
  methods: {
    //展开收起
    isOpen() {
      this.lists.openFlag = !this.lists.openFlag;
    },
    imganClick(data){
      this.$parent.dialogImageUrl1 = data;
      this.$parent.dialogVisible1 = true;
    },
    audiobofang1(){
      this.$parent.audiobofang()
    },
    videobofang1(){
      this.$parent.videobofang()
    }
  },
};
</script>

<style lang="scss" scoped>
.yxb_analyze {
  padding: 23px 25px 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #fff;
  .analyze_head {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #999;
    margin-bottom: 20px;
    .analyze_title_left {
      position: relative;
      color: #333;
      font-weight: bold;
      line-height: 28px;
      font-size: 20px;
      padding: 0 3px;
      em {
        width: 100%;
        height: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background: #c7e2ff;
      }
      div {
        position: relative;
        z-index: 2;
      }
    }
    .analyze_title_right {
      width: 58px;
      height: 26px;
      border: 1px solid #dbdbdb;
      border-radius: 14px;
      line-height: 26px;
      color: #507fff;
      text-align: center;
      cursor: pointer;
    }
  }
  .analyze_const {
    font-size: 16px;
    color: #333;
    line-height: 32px;
    margin-bottom: 12px;
  }
  .analyze_img {
    margin-bottom: 10px;
    img {
      width: 130px;
      height: 130px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .analyze_audio {
    width: 480px;
    height: 70px;
    padding-top: 20px;
    background: #f1f3f4;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
    span {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      padding: 0 7px;
      height: 20px;
      font-size: 12px;
      color: #fff;
      line-height: 20px;
      text-align: center;
      background: #afbcc9;
      border-radius: 8px 0 8px 0;
    }
    audio {
      width: 100%;
    }
  }
  .analyze_video {
    width: 480px;
    height: 270px;
    background: #4b4c4e;
    border-radius: 8px;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
    }
  }
}
.unfold {
  .analyze_const {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .analyze_img {
    display: none;
  }
  .analyze_audio {
    display: none;
  }
  .analyze_video {
    display: none;
  }
}
</style>
