import { get, post } from '../../../../api/index';
export const info = params => get(`/api/assignment/v1/info`, params); // 作业详情
export const addrespondence = params => post(`/api/assignment/v1/addrespondence`, params); //我的作业作答&&修改作答
export const respondenceinfo = params => get(`/api/assignment/v1/respondenceinfo`, params); // 我的作答
// export const delrespondence = params => get(`/api/assignment/v1/delrespondence`, params); // 删除作答


export const answerinfo = params => get(`/api/assignment/v1/answerinfo`, params); // （作业栏目中的）作业详情
export const addworkanswer = params => post(`/api/assignment/v1/addworkanswer`, params); //（作业栏目中的）作业作答

